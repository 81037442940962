/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function AdminDashboard() {
  const navigate = useNavigate();
  const [admissions, setAdmissions] = useState([]);
  const [open, setOpen] = useState(false);
  const [docToDelete, setDocToDelete] = useState();
  const [deleting, setDeleting] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (id) => {
    setOpen(true);
    setDocToDelete(id);
  };

  const handleConfirm = async () => {
    console.log("THE DOC TO DELETE HAS ID: ", docToDelete);
    setDeleting(true);
    const deleted = await deleteAdmission(docToDelete);

    if (deleted == true) {
      toast.success("Admission deleted successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setOpen(false);
      window.location.reload();

    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setDeleting(false);
  };

  function logout() {
    localStorage.setItem("loggedIn", false);
    navigate("/login");
  }

  // Create a method to read all items in the "admissions" collection
  const getAdmissions = async () => {
    try {
      const admissionsCollection = await getDocs(
        query(
          collection(db, "bursary_applications"),
          orderBy("createdAt", "desc")
        )
      );
      const admissionsData = [];
      admissionsCollection.forEach((doc) => {
        admissionsData.push({ id: doc.id, ...doc.data() });
      });
      console.log("LENGTH OF ADMISSIONS: ", admissionsData.length);
      setAdmissions(admissionsData);
    } catch (error) {
      console.error("Error reading admissions collection:", error);
      return [];
    }
  };

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
  };

  // METHOD TO CALCULATE UCE GRADE
  const calculateUCEGrade = (admissionData) => {
    // get the last letter of the score and convert it to a number

    var math = getGrade(admissionData.math);
    var english = getGrade(admissionData.english);
    var physics = getGrade(admissionData.physics);
    var chemistry = getGrade(admissionData.chemistry);
    var biology = getGrade(admissionData.biology);
    var geography = getGrade(admissionData.geography);
    var history = getGrade(admissionData.history);
    const selectedSubjectGrade = getGrade(admissionData.selectedSubjectGrade);

    const aggregates =
      math +
      english +
      physics +
      chemistry +
      biology +
      geography +
      history +
      selectedSubjectGrade;

    return aggregates;
  };

  // METHO

  // METHOD TO CALCULATE UACE GRADE
  const calculateUACEGrade = (admissionData) => {
    // get the last letter of the score and convert it to a number

    var principal1 = getUACEGrade(admissionData.principal1Grade);
    var principal2 = getUACEGrade(admissionData.principal2Grade);
    var principal3 = getUACEGrade(admissionData.principal3Grade);
    var subsidiary = getUACEGrade(admissionData.subsidiaryGrade);
    var gp = getUACEGrade(admissionData.gp);

    const points = principal1 + principal2 + principal3 + subsidiary + gp;

    return points;
  };

  function getGrade(score) {
    // get the last letter of the score and convert it to a number
    const lastLetter = score.slice(-1);
    const grade = parseInt(lastLetter);
    return grade;
  }
  function getUACEGrade(score) {
    if (!score) {
      return 0;
    }

    // convert score to uppercase
    score = score.toUpperCase();

    if (score === "A") {
      return 6;
    } else if (score === "B") {
      return 5;
    } else if (score === "C") {
      return 4;
    } else if (score === "D") {
      return 3;
    } else if (score === "E") {
      return 2;
    } else if (score === "O") {
      return 1;
    } else if (score === "F") {
      return 0;
    }
  }

  // GETTING THE ADMISSIONS
  useEffect(() => {
    const loggedIn = localStorage.getItem("loggedIn");
    console.log("IS LOGGED IN: ", loggedIn);

    // CHECK IF THE USER IS LOGGED IN
    if (loggedIn === "false") {
      toast.success("Access Denied");
      window.location.href = "/login";
      return;
    }
    getAdmissions();
  }, []);

  // METHOD TO HANDLE DELETING AN ENTRY
  const deleteAdmission = async (id) => {
    try {
      await deleteDoc(doc(db, "bursary_applications", id));
      return true;
    } catch (error) {
      toast.error("Something went wrong: " + error.message);
      return false;
    }
  };

  return (
    <div className="dashboard dashboard_1">
      <div className="full_container">
        <div className="inner_container">
          {/* Sidebar  */}
          <nav id="sidebar">
            <div className="sidebar_blog_1">
              <div className="sidebar-header">
                <div className="logo_section">
                  <a href="index.html">
                    <img
                      className="logo_icon img-responsive"
                      src="https://kti.ac.ug/wp-content/uploads/2023/02/KTI-Logo-7.png"
                      alt="#"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="sidebar_blog_2">
              <h4>ADMIN DASHBOARD</h4>
              <ul className="list-unstyled components">
                {/* DASHBOARD */}
                <li className="active">
                  <a
                    href="#dashboard"
                    data-toggle="collapse"
                    aria-expanded="false"
                    className="dropdown-toggle"
                  >
                    <i className="fa fa-dashboard yellow_color" />{" "}
                    <span>Dashboard</span>
                  </a>
                  <ul className="collapse list-unstyled" id="dashboard">
                    <li>
                      <a href="o">
                        &gt; <span>Main Dashboard</span>
                      </a>
                    </li>
                  </ul>
                </li>

                {/* LOGOUT */}
                <li className="active">
                  <a href="#" onClick={() => logout()}>
                    <i className="fa fa-sign-out red_color" />{" "}
                    <span>Logout</span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          {/* end sidebar */}
          {/* right content */}
          <div id="content">
            {/* topbar */}
            <div className="topbar">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="full">
                  <button
                    type="button"
                    id="sidebarCollapse"
                    className="sidebar_toggle"
                  >
                    <i className="fa fa-bars" />
                  </button>
                  <div className="right_topbar">
                    <div className="icon_info">
                      <ul className="user_profile_dd">
                        <li>
                          <a
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            href="d"
                          >
                            <img
                              className="img-responsive rounded-circle"
                              src="https://kti.ac.ug/wp-content/uploads/2023/02/KTI-Logo-7.png"
                              alt="#"
                            />
                            <span className="name_user">ADMIN</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
            {/* end topbar */}
            {/* dashboard inner */}
            <div className="midde_cont">
              <div className="container-fluid">
                <div className="row column_title">
                  <div className="col-md-12">
                    <div className="page_title">
                      <h2>Dashboard</h2>
                    </div>
                  </div>
                </div>
                <div className="row column1">
                  <div className="col-md-12">
                    <div className="full counter_section margin_bottom_30 bg-success">
                      <div className="couter_icon">
                        <div>
                          <i className="fa fa-user yellow_color" />
                        </div>
                      </div>
                      <div className="counter_no">
                        <div>
                          <p className="total_no text-white">
                            {admissions.length}
                          </p>
                          <p className="head_couter text-white">
                            Total Bursary Applicants
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* APPLICANTS GRAPH */}
                <div className="row column2 graph margin_bottom_30">
                  <div className="col-md-l2 col-lg-12">
                    <div className="white_shd full">
                      <div className="full graph_head">
                        <div className="heading1 margin_0">
                          <h2>BURSARY APPLICANTS </h2>
                        </div>
                      </div>

                      {/* TABLE OF APPLICANTS */}
                      <div className="table_section padding_infor_info">
                        <div className="table-responsive">
                          <table className="table table-hover">
                            {/* Table Header */}
                            <thead>
                              <tr>
                                <th className="text-center">Name</th>
                                <th className="text-center">Email</th>
                                <th className="text-center">Phone</th>
                                <th className="text-center">Sex</th>
                                <th className="text-center">DOB</th>
                                <th className="text-center">1st Choice</th>
                                <th className="text-center">2nd Choice</th>
                                <th className="text-center">3rd Choice</th>
                                <th className="text-center">4th Choice</th>
                                <th className="text-center">
                                  Application Date
                                </th>
                                <th className="text-center">Address</th>
                                <th className="text-center">
                                  Reason For Applying
                                </th>
                                <th className="text-center">
                                  Source of Tuition
                                </th>
                                <th className="text-center">Nationality</th>

                                <th className="text-center">UCE</th>
                                <th className="text-center">UACE</th>
                                <th className="text-center">UBTEB</th>
                                <th className="text-center">
                                  Next of Kin Name
                                </th>
                                <th className="text-center">
                                  Next of Kin Address
                                </th>
                                <th className="text-center">
                                  Next of Kin Phone
                                </th>

                                <th className="text-center">Relative Name</th>
                                <th className="text-center">
                                  Relative Address
                                </th>
                                <th className="text-center">Relative Phone</th>
                                <th className="text-center">Actions</th>
                              </tr>
                            </thead>

                            {/* Table Body */}
                            <tbody>
                              {admissions.map((admission, index) => (
                                <tr key={index}>
                                  <td className="text-center">
                                    {admission.name}
                                  </td>
                                  <td className="text-center">
                                    {admission.email}
                                  </td>
                                  <td className="text-center">
                                    {admission.phoneNumber}
                                  </td>
                                  <td className="text-center">
                                    {admission.sex}
                                  </td>

                                  <td className="text-center">
                                    {formatDate(admission.dob)}
                                  </td>

                                  <td className="text-center">
                                    {admission.firstChoice}
                                  </td>
                                  <td className="text-center">
                                    {admission.secondChoice}
                                  </td>
                                  <td className="text-center">
                                    {admission.thirdChoice}
                                  </td>
                                  <td className="text-center">
                                    {admission.fourthChoice}
                                  </td>
                                  <td className="text-center">
                                    {formatDate(admission.createdAt)}
                                  </td>
                                  <td className="text-center">
                                    {admission.address}
                                  </td>
                                  <td className="text-center">
                                    {admission.reason}
                                  </td>
                                  <td className="text-center">
                                    {admission.financeSource}
                                  </td>
                                  <td className="text-center">
                                    {admission.nationality}
                                  </td>

                                  {/* UCE */}
                                  <td className="text-center">
                                    <p className="text-center">
                                      {" "}
                                      {calculateUCEGrade(admission)}{" "}
                                    </p>
                                  </td>

                                  {/* UACE */}
                                  <td className="text-center">
                                    <p className="text-center">
                                      {" "}
                                      {calculateUACEGrade(admission)}{" "}
                                    </p>
                                  </td>

                                  {/* UBTEB */}
                                  <td className="text-center">
                                    {admission.UBTEBTranscript && (
                                      <a
                                        href={admission.UBTEBTranscript}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <img
                                          src="https://cdn-icons-png.flaticon.com/512/888/888108.png"
                                          alt="UBTEB"
                                          height={30}
                                        />
                                      </a>
                                    )}

                                    {!admission.UBTEBTranscript && (
                                      <p className="text-center">N/A</p>
                                    )}
                                  </td>

                                  {/* Next Of Kin */}
                                  <td className="text-center">
                                    {admission.NOKName}
                                  </td>
                                  <td className="text-center">
                                    {admission.NOKAddress}
                                  </td>
                                  <td className="text-center">
                                    {admission.NOKPhone}
                                  </td>

                                  {/* Relative details */}
                                  <td className="text-center">
                                    {admission.relativeName}
                                  </td>
                                  <td className="text-center">
                                    {admission.relativeAddress}
                                  </td>
                                  <td className="text-center">
                                    {admission.relativePhone}
                                  </td>
                                  <td className="text-center cursor">
                                    <i
                                      className="bi bi-trash"
                                      onClick={() => handleOpen(admission.id)}
                                      style={{ color: "red" }}
                                    ></i>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={`modal ${open ? "show" : ""}`}
                style={{ display: open ? "block" : "none" }}
                tabIndex="-1"
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Confirm Action</h5>
                      <button
                        type="button"
                        className="close"
                        onClick={handleClose}
                      >
                        <span>&times;</span>
                      </button>
                    </div>
                    <div className="modal-body ">
                      "Are you sure you want to delete this admission?"
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleConfirm}
                        disabled={deleting}
                      >
                        {deleting ? "Deleting..." : "Delete"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* footer */}
              <div className="container-fluid">
                <div className="footer">
                  <p>
                    Copyright © 2024 Kyadondo Technical Institute. All rights
                    reserved.
                  </p>
                </div>
              </div>
            </div>
            {/* end dashboard inner */}
          </div>
        </div>
      </div>
    </div>
  );
}
